/* eslint-disable import/prefer-default-export */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'
import { ThemeProvider } from './src/themeProvider'

const wrapRootElement = ({ element }) => <ThemeProvider>{element}</ThemeProvider>

export { wrapRootElement }

// You can delete this file if you're not using it
