import React, {useState, useEffect, useMemo} from 'react'
import window from 'global'

const myContext = React.createContext({ theme: 'white', setTheme: () => {} }, undefined)

function ThemeProvider(props) {
  const { children } = props
  const [theme, setTheme] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'white')
  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addListener((e) => e.matches && setTheme('dark'))
    window.matchMedia('(prefers-color-scheme: light)').addListener((e) => e.matches && setTheme('white'))
  })
  const changeTheme = () => setTheme(theme === 'white' ? 'dark' : 'white')
  const contextValue = useMemo(()=> ({
    theme,
    changeTheme
  }), [theme, changeTheme])

  return (
    <myContext.Provider
      value={contextValue}
    >
      {children}
    </myContext.Provider>
  )
}

export { ThemeProvider, myContext }
