exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-cross-platform-study-js": () => import("./../../../src/pages/projects/cross-platform-study.js" /* webpackChunkName: "component---src-pages-projects-cross-platform-study-js" */),
  "component---src-pages-projects-netflax-calendar-js": () => import("./../../../src/pages/projects/netflax-calendar.js" /* webpackChunkName: "component---src-pages-projects-netflax-calendar-js" */),
  "component---src-pages-projects-upturn-js": () => import("./../../../src/pages/projects/upturn.js" /* webpackChunkName: "component---src-pages-projects-upturn-js" */),
  "component---src-pages-uses-js": () => import("./../../../src/pages/uses.js" /* webpackChunkName: "component---src-pages-uses-js" */)
}

